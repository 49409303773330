<template>
  <div class="page-container">
    {{ makeHeaders }}
    <md-app>
      <md-app-toolbar v-if="list" :class="printViewClass">
        <md-button class="md-icon-button" @click="toggleMenu" v-if="!menuVisible && !printMode">
          <md-icon>menu</md-icon>
          <md-tooltip md-direction="bottom" style="font-size: medium;">
            Spalten konfigurieren
          </md-tooltip>
        </md-button>

        <div class="md-toolbar-section-start grid-x">
          <div class="cell large-3" v-if="type === 'Employees'">
            <h1 v-if="!archiveActive" class="md-title">{{ list.length }} Mitarbeiter</h1>
            <h1 v-else class="md-title" style="color: orangered;">{{ list.length }} Mitarbeiter</h1>
          </div>
          <div class="cell large-3" v-else>
            <h1 v-if="!archiveActive" class="md-title">{{ list.length }} Kind<span v-if="list.length !== 1">er</span>
            </h1>
            <h1 v-else class="md-title" style="color: orangered;">{{ list.length }} Kind<span v-if="list.length !== 1">er</span>
            </h1>
          </div>
          <div class="cell large-2 text-left">
            <md-switch v-if="!printMode" v-model="hasListTitle" class="md-primary">Titel
              <md-tooltip md-direction="bottom" style="font-size: medium;">
                Titel / Überschrift <span v-if="hasListTitle">de</span>aktivieren
              </md-tooltip>
            </md-switch>
          </div>
          <div class="cell large-7">
            <span><b>Einrichtung:</b></span>&nbsp;
            <span v-for="facility of filters['facilities']" :key="facility">{{ facility }}&nbsp;</span>
            <br>
            <span><b>Filter:</b></span>&nbsp;
            <span v-for="filter of filters['filters']" :key="filter">{{ filter }}&nbsp;</span>
          </div>
        </div>

        <div class="md-toolbar-section-end  grid-x">
          <div class="cell large-9">
            <span><b>Bereiche:</b></span>&nbsp;
            <span v-for="division of filters['divisions']"
                  :key="division">{{ labels.divisiontype[division] }}&nbsp;</span>
            <br>
            <div v-if="!archiveActive">
              <div v-if="filters['groups']">
                <span><b>Gruppen:</b></span>&nbsp;
                <span v-for="group of filters['groups']" :key="group">{{ group }}&nbsp;</span>
              </div>
            </div>
            <div v-else>
              <span><b>Jahre:</b></span>&nbsp;
              <span v-for="(year, index) of selectedYears" :key="index">
                <span v-if="index < 11">{{ year }}&nbsp;</span>
                <span v-else-if="index === 11">...</span>
              </span>
            </div>
          </div>
          <div class="cell large-3 text-right">
            <md-button v-if="!printMode" class="md-icon-button md-raised md-secondary" @click="onExport">
              <img width="25px" src="../assets/icons/ms-excel.png"/>
              <md-tooltip md-direction="bottom" style="font-size: medium;">
                Liste als Excel-Datei exportieren
              </md-tooltip>
            </md-button>
            <span>&nbsp;&nbsp;</span>
            <md-button v-if="!printMode" class="md-icon-button md-raised md-primary" @click="onPrint">
              <md-icon>print</md-icon>
              <md-tooltip md-direction="bottom" style="font-size: medium;">
                Liste jetzt drucken
              </md-tooltip>
            </md-button>
            <span>&nbsp;&nbsp;</span>
            <md-button v-if="!printMode" class="md-icon-button md-dense md-primary" @click="onClosePrintTableView">
              <md-icon>close</md-icon>
              <md-tooltip md-direction="bottom" style="font-size: medium;">
                Ansicht schliessen und zurück
              </md-tooltip>
            </md-button>
          </div>
        </div>
      </md-app-toolbar>

      <md-app-drawer id="columns-drawer" :md-active.sync="menuVisible" md-persistent="full"
                     style="width: 250px; height: 100%">
        <md-toolbar class="md-transparent" md-elevation="0">
          <span class="md-title">Spalten</span>
          <div class="md-toolbar-section-end">
            <md-button class="md-icon-button md-dense" @click="toggleMenu">
              <md-icon>keyboard_arrow_left</md-icon>
            </md-button>
          </div>
        </md-toolbar>
        <hr style="margin-top: 0; margin-bottom: 0;">
        <md-list style="padding: 1rem;">
          <div v-for="header of headers" :key="header.label">
            <md-checkbox v-if="!header.label.startsWith('>')" v-model="header.visible" class="md-primary narrow"
                         @change="onSaveSettings">
              {{ header.label }}
            </md-checkbox>
            <span v-else class="md-title"><hr
                style="margin-top: 10px; margin-bottom: 10px;">{{ header.label.substring(1) }}</span>
          </div>
        </md-list>
        <hr style="margin-top: 0; margin-bottom: 0;">
      </md-app-drawer>

      <md-app-content :style="appContentHeight">

        <div v-if="hasListTitle" class="grid-x">
          <div class="cell large-3 medium-2 small-0"></div>
          <div class="cell large-6 medium-8 small-12 text-center">
            <md-field>
              <label v-if="!printMode">Titel der Liste</label>
              <md-input v-model="listTitle" class="text-center" @keyup="onSaveSettings"></md-input>
            </md-field>
          </div>
          <div class="cell large-3 medium-2 small-0"></div>
        </div>

        <table id="customPrintTable">
          <thead style="border-bottom: 1px solid black;">
          <tr>
            <th v-for="header of headers" v-if="header.visible" @click="sortTable(header)" style="cursor: pointer;"
                :key="header.id">
              <span><md-icon style="font-size: 16px!important;">{{ header.dir }}</md-icon>{{ header.label }}</span>
              <md-tooltip md-direction="top" style="font-size: medium;">
                Sortieren nach {{ header.label }} {{ header.dir }}
              </md-tooltip>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row, k) of list" :key="k" class="border_bottom">
            <td v-for="(column, i, j) of row" v-if="headers[j].visible" v-html="emptyNull(column)" :key="i"
                style="padding-left: 34px;"></td>
          </tr>
          </tbody>
        </table>
      </md-app-content>

    </md-app>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showConfirmExcelDialog">
      <div class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title><img width="25px" src="../assets/icons/ms-excel.png"/>&nbsp;&nbsp;&nbsp;Aktuelle Liste als Excel-Datei exportieren
        </md-dialog-title>
        <div class="cell">
          <md-content class="text-center">Soll die aktuelle Liste als Excel-Datei exportiert werden?</md-content>
          <br>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        </div>
        <div class="cell text-center">
          <button :disabled="sending" @click="onExportExcelFile" class="button success"><i class="fi-download"></i>&nbsp;&nbsp;&nbsp;Ja,
            Excel-Datei erzeugen
          </button>
          <button :disabled="sending" @click="showConfirmExcelDialog = false"
                  class="button alert"
                  style="margin-left: 1rem;"
                  type="button">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button @click="showConfirmExcelDialog = false" class="close-button" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

  </div>
</template>

<script>
import jQuery from 'jquery';
import ChildService from "../services/ChildService";
import HttpErrorHandler from "../services/HttpErrorHandler";
import Vue from "vue";

export default {
  name: 'CustomPrintTable',

  created() {
    window.addEventListener("resize", this.resizeAppContent, { passive: true });
  },

  destroyed() {
    window.removeEventListener("resize", this.resizeAppContent);
  },

  mounted() {
    this.restoreUserSettings();
    this.resizeAppContent();
  },

  data: () => ({
    menuVisible: false,
    printMode: false,
    headers: [],
    hasListTitle: false,
    listTitle: '',
    appContentHeight: 'height: 600px;',
    archiveActive: false,
    selectedYears: [],

    showConfirmExcelDialog: false,
    sending: false,

    listXLS: {
      url: null,
      link: null,
      blob: null,
    },
  }),

  methods: {

    cleanXLS() {
      if (this.listXLS.url) {
        this.listXLS.url = window.URL.revokeObjectURL(this.listXLS.url);
        this.listXLS.blob = null;
      }
      this.listXLS.url = null;
    },

    restoreUserSettings() {
      this.archiveActive = (localStorage.getItem(this.user.md5 + '@archiveActive') === 'true');
      if (localStorage.getItem(this.user.md5 + '@selectedYears')) {
        this.selectedYears = JSON.parse(localStorage.getItem(this.user.md5 + '@selectedYears'));
      }
    },

    resizeAppContent() {
      this.appContentHeight = 'height: ' + String(Number(jQuery(window).height()) - 64) + 'px;';
    },

    onSaveSettings() {
      localStorage.setItem(this.user.md5 + '@printTableList' + this.type, JSON.stringify(this.headers));
      localStorage.setItem(this.user.md5 + '@printTableListTitle' + this.type, JSON.stringify(this.listTitle));
      localStorage.setItem(this.user.md5 + '@printTableListHasTitle' + this.type, JSON.stringify(this.hasListTitle));
    },

    onExport() {
      this.showConfirmExcelDialog = true;
    },

    stripHtml(val) {
      let value = val.toString() || '';
      if (value.indexOf('<span') >= 0) {
        let content = value.substr(value.indexOf('</span>') + 8);
        if (value.indexOf('&#10003') >= 0) {
          value = 'ja';
        }
        if (value.indexOf('&#10005;') >= 0) {
          value = 'nein';
        }
        if (value.indexOf('&#9794;') >= 0) {
          value = this.labels.sex['MALE'];
        }
        if (value.indexOf('&#9792') >= 0) {
          value = this.labels.sex['FEMALE'];
        }
        if (value.indexOf('&#9954;') >= 0) {
          value = this.labels.sex['DIVERSE'];
        }

        if (content.length > 0) {
          value = content;
        }
      }
      return value;
    },

    onExportExcelFile() {
      let fields = [];
      this.list.forEach(item => {
        let itemFields = [];
        let index = 0;
        Object.entries(item).forEach(entry => {
          let key = entry[0];
          let value = entry[1] || '';
          if (this.headers[index].visible) {

            if (key.startsWith('_') || key.startsWith('>')) {
              key = key.substr(1);
            }
            let field = { name: key, value: this.stripHtml(value)};
            itemFields.push(field);
          }

          index++;
        })
        fields.push(itemFields);
      })

      this.cleanXLS();
      this.exportExcelFile({fields: fields});
    },

    exportExcelFile(exportStructure) {
      this.sending = true;
      ChildService.exportExcel(exportStructure)
          .then(response => {
            this.listXLS.blob = new Blob([response.data, 'utf-8'], { type: 'application/octet-stream' });
            this.listXLS.url = window.URL.createObjectURL(this.listXLS.blob);

            if (this.listXLS.link) {
              document.body.removeChild(this.listXLS.link);
            }
            this.listXLS.link = document.createElement('a');
            let id = this.organization.name;
            if (this.type === 'Employees') {
              id += ' Mitarbeiterdaten'
            } else {
              id += ' Kinderdaten'
            }
            if (this.hasListTitle && this.listTitle.length > 0) {
              id += ' ' + this.listTitle;
            }
            this.listXLS.link.href = this.listXLS.url;
            this.listXLS.link.setAttribute('download', id + '.xls');
            this.listXLS.link.setAttribute('id', id);
            document.body.appendChild(this.listXLS.link);
            this.listXLS.link.click();

            this.sending = false;
            this.showConfirmExcelDialog = false;
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Erzeugen der Excel-Datei');
            this.sending = false;
          })
    },

    onPrint() {
      this.printMode = true;
      this.menuVisible = false;
      this.appContentHeight = '';
      if (this.isDevEnv) {
        jQuery('#__vconsole').hide();
      }
      setTimeout(() => window.print(), 250);
      window.onafterprint = () => {
        this.printMode = false;
        if (this.isDevEnv) {
          jQuery('#__vconsole').show();
          this.resizeAppContent();
        }
      }
    },

    onClosePrintTableView() {
      this.$router.history.go(-1);
      setTimeout(() => window.dispatchEvent(new Event('resize')), 150);
    },

    toggleMenu() {
      this.menuVisible = !this.menuVisible;
      jQuery('#columns-drawer').height(jQuery(window).height());
    },

    updateHeaders() {
      if (this.list.length !== this.headers.length) {
        this.headers = [];
        if (this.list.length > 0) {
          Object.keys(this.list[0]).forEach((key) => {
            let vis = true;
            let lab = key;
            if (key.startsWith('_')) {
              vis = false;
              lab = key.substring(1);
            }
            if (key.startsWith('>')) {
              vis = false;
            }
            let numeric = lab.match('Nr') != null;
            let id = 'header-' + this.headers.length;
            this.headers.push({id: id, label: lab, visible: vis, dir: '', numeric: numeric})
          });
        }
      }

      // Check if settings are stored
      if (localStorage.getItem(this.user.md5 + '@printTableList' + this.type)) {

        // Load from local storage if number of columns has not changed
        let storedHeaders = JSON.parse(localStorage.getItem(this.user.md5 + '@printTableList' + this.type));
        if (storedHeaders.length === this.headers.length) {
          this.headers = storedHeaders;
        }
      }

      // Check if further settings are stored
      if (localStorage.getItem(this.user.md5 + '@printTableListTitle' + this.type)) {
        this.listTitle = JSON.parse(localStorage.getItem(this.user.md5 + '@printTableListTitle' + this.type));
      }
      if (localStorage.getItem(this.user.md5 + '@printTableListHasTitle')) {
        this.hasListTitle = JSON.parse(localStorage.getItem(this.user.md5 + '@printTableListHasTitle' + this.type));
      }
    },

    sortTable(h) {
      let n = 0;
      let nn = 0;
      let r = 0;
      let k = 0;
      for (let header of this.headers) {
        if (header.visible) {
          if (h.label === header.label) {
            n = r;
            nn = k;
          }
          r++;
        }
        k++;
      }

      let table, rows, switching, i, x, y, shouldSwitch, dir, switchCount = 0;
      table = document.getElementById("customPrintTable");
      switching = true;
      // Set the sorting direction to ascending:
      dir = "asc";
      /* Make a loop that will continue until
      no switching has been done: */
      while (switching) {
        // Start by saying: no switching is done:
        switching = false;
        rows = table.rows;
        /* Loop through all table rows (except the
        first, which contains table headers): */
        for (i = 1; i < (rows.length - 1); i++) {
          // Start by saying there should be no switching:
          shouldSwitch = false;
          /* Get the two elements you want to compare,
          one from current row and one from the next: */
          x = rows[i].getElementsByTagName("TD")[n];
          y = rows[i + 1].getElementsByTagName("TD")[n];

          let xNumeric = Number(x.innerHTML);
          let yNumeric = Number(y.innerHTML);

          // Handle some exceptions as numeric values
          if (['Alter', 'Geburtstag', '1. Masernimpfung', '2. Masernimpfung', 'Eintritt', 'Austritt', 'Datum Mandatserteilung'].includes(h.label)) {

            h.numeric = true;
            let xPayload = x.innerHTML.slice(-10);
            let yPayload = y.innerHTML.slice(-10);

            xNumeric = Vue.moment(xPayload, 'DD_MM_YYYY');
            yNumeric = Vue.moment(yPayload, 'DD_MM_YYYY');

            if (h.label === 'Alter') {
              xNumeric = 365 * Number(x.innerHTML.substring(0, x.innerHTML.indexOf(' ') - 1)) + 30 * Number(x.innerHTML.substring(x.innerHTML.indexOf(' ') + 1, x.innerHTML.length - 1));
              yNumeric = 365 * Number(y.innerHTML.substring(0, y.innerHTML.indexOf(' ') - 1)) + 30 * Number(y.innerHTML.substring(y.innerHTML.indexOf(' ') + 1, y.innerHTML.length - 1));
            }

            xNumeric = +xNumeric || 0;
            yNumeric = +yNumeric || 0;
          }

          /* Check if the two rows should switch place,
          based on the direction, asc or desc: */
          if (dir === "asc") {
            if (h.numeric) {
              if (xNumeric > yNumeric) {
                // If so, mark as a switch and break the loop:
                shouldSwitch = true;
                break;
              }
            } else {
              if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
                // If so, mark as a switch and break the loop:
                shouldSwitch = true;
                break;
              }
            }

          } else if (dir === "desc") {
            if (h.numeric) {
              if (xNumeric < yNumeric) {
                // If so, mark as a switch and break the loop:
                shouldSwitch = true;
                break;
              }
            } else {
              if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
                // If so, mark as a switch and break the loop:
                shouldSwitch = true;
                break;
              }
            }
          }
        }
        if (shouldSwitch) {
          /* If a switch has been marked, make the switch
          and mark that a switch has been done: */
          rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
          switching = true;
          // Each time a switch is done, increase this count by 1:
          switchCount++;
        } else {
          /* If no switching has been done AND the direction is "asc",
          set the direction to "desc" and run the while loop again. */
          if (switchCount === 0 && dir === "asc") {
            dir = "desc";
            switching = true;
          }
        }
      }

      for (let header of this.headers) {
        header.dir = '';
      }

      if (dir === "desc") {
        this.headers[nn].dir = 'arrow_downward';
      } else {
        this.headers[nn].dir = 'arrow_upward';
      }
    },

    emptyNull(value) {
      if (value === null) {
        return '';
      } else {
        return value;
      }
    },
  },

  computed: {

    printViewClass() {
      if (this.archiveActive) {
        return 'archive-active';
      }
    },

    makeHeaders() {
      this.updateHeaders();
      return '';
    },

    list() {
      return this.$store.getters.printTableList;
    },

    filters() {
      return this.$store.getters.printTableFilters;
    },

    type() {
      return this.$store.getters.printTableType;
    },

    isDevEnv() {
      return process.env.NODE_ENV === 'development';
    },

    labels() {
      return this.$store.getters.labels
    },

    user() {
      return this.$store.getters.user;
    },

    organization() {
      return this.$store.getters.organization;
    },
  }
}
</script>

<style lang="scss" scoped>

.md-app-content {
  padding: 0;
}

.archive-active {
  background: wheat;
}

.narrow {
  margin: 8px;
}

.md-toolbar.md-theme-default .md-title {
  color: cornflowerblue;
}

tr.border_bottom td {
  border-bottom: 1px solid lightgray;
}

@media print {

  table {
    margin: 1rem 0 1rem 0;
  }

  tr {
    border-bottom: 1px solid #f1f1f1 !important;
  }

  #content {
    padding: 1rem;
  }

  .md-theme-dark thead, .md-theme-dark tbody, .md-theme-dark tfoot {
    border: 1px solid #f1f1f1;
    background-color: #424242;
  }

}
</style>
